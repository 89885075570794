<template>
  <div id="measuringTools">
    <el-dialog
      :title="measuringToolsFormTitle"
      width="1200px"
      :visible.sync="measuringToolsDialogVisible"
      :close-on-click-modal="false"
      @close="measuringToolsDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="measuringToolsFormRef"
        :model="measuringToolsForm"
        :rules="measuringToolsFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="编码" prop="code">
              <el-input v-model="measuringToolsForm.code" placeholder="请输入编码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input v-model="measuringToolsForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格型号" prop="spec">
              <el-input v-model="measuringToolsForm.spec" placeholder="请输入规格型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="厂家" prop="factory">
              <el-input v-model="measuringToolsForm.factory" placeholder="请输入厂家" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用部门" prop="useDept">
              <el-input v-model="measuringToolsForm.useDept" placeholder="请输入使用部门" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="校准情况">
          <vxe-toolbar
            v-if="measuringToolsFormTitle === '新增计量器具台账'
              || measuringToolsFormTitle === '修改计量器具台账'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="toolsList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="verificationDate"
              title="检定/校准日期"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="validPeriod"
              title="有效期"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="verificationUnit"
              title="检定/校准单位或人员"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="verificationResults"
              title="检定/校准结果"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="measuringToolsFormTitle === '新增计量器具台账'
                || measuringToolsFormTitle === '修改计量器具台账'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="measuringToolsDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="measuringToolsFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="measuringToolsFormTitle === '计量器具台账详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="measuringToolsPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="code" label="编码" />
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="factory" label="厂家" />
      <el-table-column prop="useDept" label="使用部门" />
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <span v-if="scope.row.validPeriod" :style="{ color: scope.row.status === 1 ? 'red' : '#000' }">
            {{ scope.row.validPeriod.substring(0, 10) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="measuringToolsPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMeasuringTools,
  deleteMeasuringTools,
  updateMeasuringTools,
  selectMeasuringToolsInfo,
  selectMeasuringToolsList
} from '@/api/quality/measuringTools'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      measuringToolsDialogVisible: false,
      measuringToolsFormTitle: '',
      measuringToolsForm: {
        id: '',
        code: '',
        name: '',
        spec: '',
        factory: '',
        useDept: '',
        validPeriod: '',
        toolsJson: ''
      },
      measuringToolsFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      measuringToolsPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      toolsList: []
    }
  },
  created () {
    selectMeasuringToolsList(this.searchForm).then(res => {
      this.measuringToolsPage = res
    })
  },
  methods: {
    measuringToolsDialogClose () {
      this.$refs.measuringToolsFormRef.resetFields()
      this.toolsList = []
    },
    measuringToolsFormSubmit () {
      if (this.measuringToolsFormTitle === '计量器具台账详情') {
        this.measuringToolsDialogVisible = false
        return
      }
      this.$refs.measuringToolsFormRef.validate(async valid => {
        if (valid) {
          this.measuringToolsForm.validPeriod = this.$refs.xTable.getTableData().tableData.slice(-1)[0].validPeriod
          this.measuringToolsForm.toolsJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.measuringToolsFormTitle === '新增计量器具台账') {
            this.measuringToolsForm.id = ''
            await addMeasuringTools(this.measuringToolsForm)
          } else if (this.measuringToolsFormTitle === '修改计量器具台账') {
            await updateMeasuringTools(this.measuringToolsForm)
          }
          this.measuringToolsPage = await selectMeasuringToolsList(this.searchForm)
          this.measuringToolsDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.measuringToolsFormTitle = '新增计量器具台账'
      this.measuringToolsDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMeasuringTools(row.id)
        if (this.measuringToolsPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.measuringToolsPage = await selectMeasuringToolsList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.measuringToolsFormTitle = '修改计量器具台账'
      this.measuringToolsDialogVisible = true
      this.selectMeasuringToolsInfoById(row.id)
    },
    handleInfo (index, row) {
      this.measuringToolsFormTitle = '计量器具台账详情'
      this.measuringToolsDialogVisible = true
      this.selectMeasuringToolsInfoById(row.id)
    },
    selectMeasuringToolsInfoById (id) {
      selectMeasuringToolsInfo(id).then(res => {
        this.measuringToolsForm.id = res.id
        this.measuringToolsForm.code = res.code
        this.measuringToolsForm.name = res.name
        this.measuringToolsForm.spec = res.spec
        this.measuringToolsForm.factory = res.factory
        this.measuringToolsForm.useDept = res.useDept
        this.toolsList = res.toolsList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMeasuringToolsList(this.searchForm).then(res => {
        this.measuringToolsPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMeasuringToolsList(this.searchForm).then(res => {
        this.measuringToolsPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMeasuringToolsList(this.searchForm).then(res => {
        this.measuringToolsPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `计量器具台账${this.measuringToolsForm.name}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
